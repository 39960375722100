<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />

    <check-order
      :store-module-name="STORE_MODULE_NAME"
      :is-check-order-active.sync="isCheckOrderActive"
      :show.sync="show"
      :data="checkOrderData"
    />

    <link-payment-modal
      :store-module-name="STORE_MODULE_NAME"
      :is-link-payment-modal-active.sync="isLinkPaymentModalActive"
      :show.sync="show"
      :data="modalData"
    />

    <link-tracking-modal
      :store-module-name="STORE_MODULE_NAME"
      :is-link-tracking-modal-active.sync="isLinkTrackingModalActive"
      :show.sync="show"
      :data="modalData"
    />

    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="text-center mt-2 mb-0">
          <b-badge
            pill
            variant="primary"
            class="badge-glow"
          >
            {{ $t('Payment Status') }}
          </b-badge>
        </div>
        <b-tabs
          v-if="countData"
          class="mt-2 mb-2"
          pills
          :nav-class="orderStatusPills()"
          align="center"
        >
          <b-tab
            :active="orderStatus === 'all' ? true : false"
            :title="$t('All') + ' (' + countData['all'] + ')'"
            @click="orderStatusChange('all')"
          />
          <b-tab
            :active="
              orderStatus === 'waiting_to_check_the_order' ? true : false
            "
            :title="
              $t('waiting_to_check_the_order'.toUpperCase()) +
                ' (' +
                countData['waiting_to_check_the_order'] +
                ')'
            "
            @click="orderStatusChange('waiting_to_check_the_order')"
          />
          <b-tab
            :active="orderStatus === 'to_be_paid' ? true : false"
            :title="
              $t('to_be_paid'.toUpperCase()) +
                ' (' +
                countData['to_be_paid'] +
                ')'
            "
            @click="orderStatusChange('to_be_paid')"
          />
          <b-tab
            :active="orderStatus === 'pending_review' ? true : false"
            :title="
              $t('pending_review'.toUpperCase()) +
                ' (' +
                countData['pending_review'] +
                ')'
            "
            @click="orderStatusChange('pending_review')"
          />
          <b-tab
            :active="orderStatus === 'to_be_shipped' ? true : false"
            :title="
              $t('to_be_shipped'.toUpperCase()) +
                ' (' +
                countData['to_be_shipped'] +
                ')'
            "
            @click="orderStatusChange('to_be_shipped')"
          />
          <b-tab
            :active="orderStatus === 'something_to_get' ? true : false"
            :title="
              $t('something_to_get'.toUpperCase()) +
                ' (' +
                countData['something_to_get'] +
                ')'
            "
            @click="orderStatusChange('something_to_get')"
          />
          <b-tab
            :active="orderStatus === 'success' ? true : false"
            :title="
              $t('success'.toUpperCase()) + ' (' + countData['success'] + ')'
            "
            @click="orderStatusChange('success')"
          />
          <b-tab
            :active="orderStatus === 'cancel' ? true : false"
            :title="
              $t('cancel'.toUpperCase()) + ' (' + countData['cancel'] + ')'
            "
            @click="orderStatusChange('cancel')"
          />
          <b-tab
            :active="orderStatus === 'refund' ? true : false"
            :title="
              $t('refund'.toUpperCase()) + ' (' + countData['refund'] + ')'
            "
            @click="orderStatusChange('refund')"
          />
          <b-tab
            :active="orderStatus === 'fail' ? true : false"
            :title="$t('fail'.toUpperCase()) + ' (' + countData['fail'] + ')'"
            @click="orderStatusChange('fail')"
          />
        </b-tabs>
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t('entries') }}</label>
            </b-col>

            <b-col
              cols="12"
              md="6"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          style="height: 60vh;"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <template #cell(shipped)="data">
            <b-button
              v-if="data.item.orderStatus === 'waiting_to_check_the_order'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              block
              @click="checkOrderOpen(data.item)"
            >
              <span class="mr-25 align-middle"> {{ $t('Check Order') }}</span>
              <feather-icon
                icon="ShoppingBagIcon"
                size="12"
              />
            </b-button>
            <b-button
              v-if="data.item.orderStatus === 'to_be_shipped'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              block
              @click="modalEdit(data.item)"
            >
              <span class="mr-25 align-middle"> {{ $t('Delivery') }}</span>
              <feather-icon
                icon="ShoppingBagIcon"
                size="12"
              />
            </b-button>
          </template>
          <template #cell(_id)="data">
            <b-card-text>{{
              subStringOrderId(data.item._id).toUpperCase()
            }}</b-card-text>
          </template>
          <template #cell(orderCode)="data">
            <b-card-text>{{
              subStringOrderId(data.item.orderCode)
            }}</b-card-text>
          </template>
          <template #cell(created)="data">
            <b-card-text>{{
              data.item.created | formatDateTime
            }}</b-card-text>
          </template>
          <template #cell(paymentMethod)="data">
            <b-card-text>{{
              data.item.paymentMethod | firstStringUpper
            }}</b-card-text>
          </template>
          <template #cell(orderStatus)="data">
            <b-badge
              pill
              :variant="orderStatusBadge(data.item.orderStatus.toUpperCase())"
              class="badge-glow"
            >
              {{ $t(data.item.orderStatus.toUpperCase()) }}
            </b-badge>
          </template>
          <!-- Column: Images -->
          <!-- <template #cell(productList)="data">
            <div
              v-for="(item ,index) in data.item.products.map(e=>{return { name:e['product']['name'], amount:e['amount']} })"
              :key="index"
            >

              <p>{{ item['name'] }} x{{ item['amount'] }}</p>
            </div>
          </template> -->
          <!-- Column: Images -->
          <template #cell(fullName)="data">
            <b-card-text>{{ data.item.userInfo.firstName }}
              {{ data.item.userInfo.lastName }}</b-card-text>
          </template>
          <!-- Column: Images -->
          <!-- <template #cell(address)="data">
            <b-card-text>
              {{ $t('Name') }} :  {{ data.item.address.name }},
              {{ $t('Phone Number') }} :  {{ data.item.address.tel }},
              {{ $t('Address') }} :  {{ data.item.address.address }},
              {{ $t('Subdistrict') }} : {{ showFromCurrentLanguage(data.item.address.subdistrictId.name)['value'] }},
              {{ $t('District') }} : {{ showFromCurrentLanguage(data.item.address.districtId.name)['value'] }},
              {{ $t('Province') }} : {{ showFromCurrentLanguage(data.item.address.provinceId.name)['value'] }},
              {{ $t('ZipCode') }} : {{ data.item.address.zipCode }}</b-card-text>
          </template> -->
          <!-- Column: Images -->
          <template #cell(branch)="data">
            <b-row>
              <b-card-text v-if="data.item.branch">{{
                showFromCurrentLanguage(data.item.branch.name).value
              }}</b-card-text>
            </b-row>
          </template>
          <template #cell(country)="data">
            <b-row>
              <b-card-text v-if="data.item.address.countryId">{{
                data.item.address.countryId.name
              }}</b-card-text>
            </b-row>
          </template>
          <template #cell(amount)="data">
            <b-row>
              <b-card-text>{{
                countProducts(data.item.products)
              }}</b-card-text>
            </b-row>
          </template>
          <template #cell(sale)="data">
            <b-row>
              <b-card-text v-if="data.item.currencyId == null">{{
                numberWithCommas(data.item.sale / 100)
              }}</b-card-text>
              <b-card-text
                v-else
              >{{
                numberWithCommas(
                  (data.item.sale_currency / 100).toFixed(2),
                )
              }}{{ data.item.currencySymbol }}</b-card-text>
            </b-row>
          </template>
          <template #cell(sale_2)="data">
            <b-row>
              <b-card-text>{{
                numberWithCommas(data.item.sale / 100)
              }}</b-card-text>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="print(data.item._id)">
                <feather-icon icon="PrinterIcon" />
                <span class="align-middle ml-50">{{ $t('Print') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="modalEdit(data.item)">
                <feather-icon icon="MenuIcon" />
                <span class="align-middle ml-50">{{ $t('Detail') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="addLinkPayment(data.item)">
                <feather-icon icon="LinkIcon" />
                <span class="align-middle ml-50">{{ $t('Link Payment') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="addLinkTracking(data.item)">
                <feather-icon icon="LinkIcon" />
                <span class="align-middle ml-50">{{ $t('Link Tracking') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/order'
import storeModuleCoupon from '@/store/services/eCommerce/coupon'
import storeModuleBranch from '@/store/services/eCommerce/branch'

import debounce from 'debounce'
import Modal from './Modal.vue'
import CheckOrder from './CheckOrder.vue'
import LinkPaymentModal from './LinkPaymentModal.vue'
import LinkTrackingModal from './LinkTrackingModal.vue'

const STORE_MODULE_NAME = 'order'
const STORE_MODULE_NAME_COUPON = 'coupon'
const STORE_MODULE_NAME_BRANCH = 'branch'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    BTab,
    vSelect,
    Modal,
    CheckOrder,
    LinkPaymentModal,
    LinkTrackingModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      orderStatus: localStorage.getItem('orderStatus')
        ? localStorage.getItem('orderStatus')
        : 'all',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      isModalActive: false,
      isCheckOrderActive: false,
      isLinkPaymentModalActive: false,
      isLinkTrackingModalActive: false,
      sidebarData: {},
      modalData: {},
      checkOrderData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'shipped', label: this.$t('Shipped'), sortable: false },
        { key: '_id', label: this.$t('OrderId'), sortable: false },
        { key: 'created', label: this.$t('Created'), sortable: false },
        { key: 'orderCode', label: this.$t('OrderCode'), sortable: false },
        {
          key: 'paymentMethod',
          label: this.$t('Payment Method'),
          sortable: false,
        },
        { key: 'orderStatus', label: this.$t('Order Status'), sortable: false },
        // { key: 'productList', label: this.$t('Product'), sortable: false },
        { key: 'userId.username', label: this.$t('Username'), sortable: false },
        { key: 'fullName', label: this.$t('FullName'), sortable: false },
        // { key: 'address', label: this.$t('Address'), sortable: false },
        { key: 'country', label: this.$t('Country'), sortable: false },
        { key: 'branch', label: this.$t('Branch'), sortable: false },

        { key: 'amount', label: this.$t('Total number of products'), sortable: false },
        { key: 'sale', label: this.$t('Price'), sortable: false },
        { key: 'sale_2', label: this.$t('Price'), sortable: false },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    countData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.count
        : null
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_COUPON)) store.registerModule(STORE_MODULE_NAME_COUPON, storeModuleCoupon)
    if (!store.hasModule(STORE_MODULE_NAME_BRANCH)) store.registerModule(STORE_MODULE_NAME_BRANCH, storeModuleBranch)
    this.get()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_COUPON)) store.unregisterModule(STORE_MODULE_NAME_COUPON)
    if (store.hasModule(STORE_MODULE_NAME_BRANCH)) store.unregisterModule(STORE_MODULE_NAME_BRANCH)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_COUPON, STORE_MODULE_NAME_BRANCH }
  },
  methods: {
    print(id) {
      if (process.env.NODE_ENV === 'production') {
        window.open(`https://api-storeapp-service.ibig.me:3316/api/webview/eCommerce/order/view/${id}`)
      } else {
        window.open(`http://localhost:3316/api/webview/eCommerce/order/view/${id}`)
      }
    },
    countProducts(products) {
      return products.reduce((a, b) => a + b.amount, 0)
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        orderStatus: this.orderStatus,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    checkOrderOpen(item) {
      this.checkOrderData = item
      this.isCheckOrderActive = true
    },
    modalEdit(item) {
      this.modalData = item
      this.isModalActive = true
    },
    addLinkPayment(item) {
      this.modalData = item
      this.isLinkPaymentModalActive = true
    },
    addLinkTracking(item) {
      this.modalData = item
      this.isLinkTrackingModalActive = true
    },
    orderStatusChange(data) {
      localStorage.setItem('orderStatus', data)
      this.orderStatus = data
      this.get()
    },
    orderStatusPills() {
      if (this.orderStatus === 'all') {
        return 'nav-pill-primary'
      }
      if (this.orderStatus === 'waiting_to_check_the_order') {
        return 'nav-pill-primary'
      }
      if (this.orderStatus === 'to_be_paid') {
        return 'nav-pill-primary'
      }
      if (this.orderStatus === 'pending_review') {
        return 'nav-pill-warning'
      }
      if (this.orderStatus === 'to_be_shipped') {
        return 'nav-pill-warning'
      }
      if (this.orderStatus === 'something_to_get') {
        return 'nav-pill-info'
      }
      if (this.orderStatus === 'success') {
        return 'nav-pill-success'
      }
      if (this.orderStatus === 'cancel') {
        return 'nav-pill-danger'
      }
      if (this.orderStatus === 'refund') {
        return 'nav-pill-warning'
      }
      if (this.orderStatus === 'fail') {
        return 'nav-pill-danger'
      }
      return 'nav-pill-dark'
    },
    orderStatusBadge(data) {
      if (data === 'WAITING_TO_CHECK_THE_ORDER') {
        return 'primary'
      }
      if (data === 'TO_BE_PAID') {
        return 'primary'
      }
      if (data === 'PENDING_REVIEW') {
        return 'primary'
      }
      if (data === 'TO_BE_SHIPPED') {
        return 'warning'
      }
      if (data === 'SOMETHING_TO_GET') {
        return 'info'
      }
      if (data === 'SUCCESS') {
        return 'success'
      }
      if (data === 'CANCEL') {
        return 'danger'
      }
      if (data === 'REFUND') {
        return 'warning'
      }
      if (data === 'FAIL') {
        return 'danger'
      }
      return 'dark'
    },
    subStringOrderId(data) {
      return data.substr(16, data.length)
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang]
      }
      return ''
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      this.get()
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      this.currentPage = 1
      this.get()
    },
    nextPage(page) {
      this.currentPage = page
      this.get()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
